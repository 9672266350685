export const KNOWN_CODES = [
  "STIFMO",
  "STIFBI",
  "STIHCO",
  "STICPR",
  "STISDE",
  "STICCO",
  "STIFSO",
  "TAMHDA",
  "TAMBDA",
  "TAMCDA",
  "TAMSMO",
  "TAMNMO",
  "TAMKDI",
  "TAMKWE",
  "TAMHBI",
  "TAMEHO",
  "PREHNE",
  "TOYNNE",
  "PREHHA",
  "PRENHA",
  "PREVHA",
  "PRECHB",
  "PRENME"
]
