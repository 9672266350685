import { CatalogItem } from "../lib/interfaces";
export const TameronCatalog: CatalogItem[] = [
  {
    code: "TAMHDA-33P3D-444019A",
    name: "Hard Front Plates",
    fullname: "Hard Front Plates",
    colors: ["Black"],
    type: "customs",
    default_color: "Black",
    sizes: {
      500: 36.99,
      1000: 36.99,
      2500: 36.99,
    },
  },
  {
    code: "TAMHDA-33P-444017B",
    name: "Soft Drive Out Plates",
    fullname: "Soft Plates",
    colors: ["Black"],
    type: "customs",
    default_color: "Black",
    sizes: {
      500: 36.99,
      1000: 36.99,
      2500: 36.99,
    },
  },
  {
    code: "die_cut_decals",
    name: "Die Cut Decals",
    fullname: "Die Cut Decals",
    colors: ["Black"],
    type: "customs",
    default_color: "Black",
    sizes: {
      500: 36.99,
      1000: 36.99,
      2500: 36.99,
    },
  },
  {
    code: "THCD",
    name: "Trailer Hitch Covers",
    fullname: "Trailer Hitch Covers",
    colors: ["Black"],
    type: "customs",
    default_color: "Black",
    sizes: {
      500: 36.99,
      1000: 36.99,
      2500: 36.99,
    },
  },
  {
    code: "NKDC1963",
    name: "Nike® Dri-FIT Polo Shirt",
    fullname: "Nike® Dri-FIT Polo Shirt",
    colors: ["Black"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 36.99,
      Medium: 36.99,
      Large: 36.99,
      XLarge: 36.99,
      "2XLarge": 38.99,
      "3XLarge": 39.99,
      "4XLarge": 40.99,
      LT: 40.99,
      XLT: 42.99,
      "2XLT": 43.99,
      "3XLT": 44.99,
      "4XLT": 45.99,
    },
  },
  {
    code: "ST650",
    name: "Sport-Tek® Polo Shirt",
    fullname: "Sport-Tek® Micro Pique Polo",
    colors: ["Iron Grey", "True Red", "Black", "True Royal"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 22.99,
      Medium: 22.99,
      Large: 22.99,
      XLarge: 22.99,
      "2XLarge": 24.99,
      "3XLarge": 26.99,
      "4XLarge": 27.99,
      "5XLarge": 28.99,
      "6XLarge": 29.99,
    },
  },
  {
    code: "J317",
    name: "Port Authority® Jacket",
    fullname: "Port Authority® Core Soft Shell Jacket",
    colors: ["Black"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 37.99,
      Medium: 37.99,
      Large: 37.99,
      XLarge: 37.99,
      "2XLarge": 38.99,
      "3XLarge": 39.99,
      "4XLarge": 40.99,
      "5XLarge": 41.99,
      "6XLarge": 42.99,
    },
  },
  {
    code: "J7710",
    name: "Port Authority® Slicker",
    fullname: "Port Authority® Northwest Slicker",
    colors: ["Black"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 58.98,
      Medium: 58.98,
      Large: 58.98,
      XLarge: 58.98,
      "2XLarge": 60.98,
      "3XLarge": 61.98,
      "4XLarge": 62.98,
    },
  },
  {
    code: "L7710",
    name: "Port Authority® Slicker",
    fullname: "Port Authority® Ladies Northwest Slicker",
    colors: ["Black"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 58.98,
      Medium: 58.98,
      Large: 58.98,
      XLarge: 58.98,
      "2XLarge": 60.98,
      "3XLarge": 61.98,
      "4XLarge": 62.98,
    },
  },
  {
    code: "MCK01086",
    name: 'Cutter & Buck® 1/4" Zip',
    fullname: 'Cutter & Buck® Eco Mens 1/4" Zip',
    colors: ["Black", "Tour Blue"],
    type: "mens",
    default_color: "Black",
    sizes: {
      Small: 59.99,
      Medium: 59.99,
      Large: 59.99,
      XLarge: 59.99,
      "2XLarge": 61.99,
      "3XLarge": 62.99,
      "4XLarge": 63.99,
    },
  },
  {
    code: "LST650",
    name: "Sport-Tek® Polo Shirt",
    fullname: "Sport-Tek® Micro Pique Polo",
    colors: ["Iron Grey", "True Red", "Black", "True Royal"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 22.99,
      Medium: 22.99,
      Large: 22.99,
      XLarge: 22.99,
      "2XLarge": 24.99,
      "3XLarge": 26.99,
      "4XLarge": 27.99,
    },
  },
  {
    code: "NKDC1991",
    name: "Ladies Nike® Polo",
    fullname: "Ladies Nike® Micro Pique Polo",
    colors: ["Black"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 36.99,
      Medium: 36.99,
      Large: 36.99,
      XLarge: 36.99,
      "2XLarge": 38.99,
    },
  },
  {
    code: "LCK00151",
    name: 'Cutter & Buck® 1/4" Zip',
    fullname: 'Cutter & Buck® Eco Ladies 1/4" Zip',
    colors: ["Black", "Tour Blue"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 59.99,
      Medium: 59.99,
      Large: 59.99,
      XLarge: 59.99,
      "2XLarge": 61.99,
      "3XLarge": 62.99,
      "4XLarge": 63.99,
    },
  },
  {
    code: "L317",
    name: "Port Authority® Jacket",
    fullname: "Port Authority® Ladies Core Soft Shell Jacket",
    colors: ["Black"],
    type: "womens",
    default_color: "Black",
    sizes: {
      Small: 37.99,
      Medium: 37.99,
      Large: 37.99,
      XLarge: 37.99,
      "2XLarge": 38.99,
      "3XLarge": 39.99,
      "4XLarge": 40.99,
    },
  },
  {
    code: "CP91L",
    name: "Port & Company Beanie Cap",
    fullname: "Port & Company Fleece-Lined Beanie Cap",
    colors: ["Black"],
    type: "mens",
    default_color: "Black",
    sizes: {
      "One Size Fits All": 10.99,
    },
  },
  {
    code: "NE1100",
    name: "New Era Interception Cap",
    fullname: "New Era Interception Cap",
    colors: ["Graphite_Black"],
    type: "mens",
    default_color: "Graphite",
    sizes: {
      Small: 13.98,
      Medium: 13.98,
      Large: 13.98,
    },
  },
];
